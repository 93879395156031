/* global Blockly */
import React, { Component } from 'react';
import util from "../../utils/es5Utils";
import AssistingBlocks from './AssistingBlocks'
import DatesCategory from "./Dates";
import WidgetValues from "./WidgetValues"
import AppActions from "./AppActions";
import EnvironmentVariables from './Variables';
import XSightTopics from './XSightTopics';
import Logic from "./Logic";
import Loops from "./Loops";
import Math from "./Math";
import Text from "./Text";
import Lists from "./Lists";
import Colour from "./Colour";
import Debug from './Debug';
import Legacy from "./Legacy";
import Search from "./Search";
import SearchBar from './Search/SearchBar'
import ToolboxList from './ToolboxList';
import MyBlocks from "./MyBlocks";
import JSBlocks from './JSBlocks';
import Scrollbar from "smooth-scrollbar";
import Connections from "./Connections";
import MobiControl from './MobiControl';
import Repeater from "./Repeater";
import TableView from "./TableView";

const SnapBlocks = ({toolboxList, context, setRestBlockError, openJSEditor}) => {
    /*Connections blocks to be added when integrated with API server*/
    /*<Connections context={this.props.context} blocks={toolboxList["Connections"]}/>*/
    const snapBlocks = [
        <Legacy context={context} key={"legacy"}/>,
        <WidgetValues context={context} blocks={toolboxList["Widget Values"]} key={"widgetValues"}/>,
        <AppActions context={context} blocks={toolboxList["App Actions"]} key={"appActions"}/>,
        <Connections context={context} blocks={toolboxList["Connections"]} key={"connections"} setRestBlockError = {setRestBlockError}/>,
        <JSBlocks context={context} key={"JSVariables"} openJSEditor={openJSEditor}/>,
        <EnvironmentVariables context={context} blocks={toolboxList["Global Variables"]} key={"envVars"}/>,
        <XSightTopics context={context} blocks={toolboxList["XSight Topics"]} key={"xSightTopics"}/>,
        <category id="variables" name="Local Variables" colour="%{BKY_VARIABLES_HUE}" custom="VARIABLE" iconclass="category category_localVariableIcon"></category>,
    ];

    const isRendered = snapBlocks.reduce((rendered, component) => {
        return !!(rendered || component.type( component.props));
    }, false);

    return (
        <React.Fragment>
            {snapBlocks}
            {
                isRendered
                    ? <sep id="separator_defaults"></sep>
                    : null
            }
        </React.Fragment>
    )
}


export default class Toolbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flyoutOpened: false,
            toolboxList: this._getToolboxList(props.context, props.libraries),
            collapsed: true,
            pinned: false,
            isSearchExpanded: false
        };
        AssistingBlocks({ context: props.context });
    }

    _getToolboxList (context, libraries) {
        return new ToolboxList({
            context: context,
            libraries: libraries
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // Update toolbox in next tick to make sure new myBlocks are injected in the DOM
        setTimeout(() => this._updateToolbox(nextProps));
    }

    componentDidMount() {
        setTimeout(() => {
            Blockly.mainWorkspace.addChangeListener(blocklyEvent => {
                if (!this.state.pinned && blocklyEvent.type == Blockly.Events.UI && blocklyEvent.element == 'category') {
                    if (!blocklyEvent.oldValue) {
                        this.expandToolbox();
                        this.setState({ collapsed: false });
                    } else if (!blocklyEvent.newValue) {
                        this.collapseToolbox();
                        this.setState({ collapsed: true });
                    }
                }
                if (!this.state.pinned && blocklyEvent.type == Blockly.Events.CREATE) {
                    this.collapseToolbox();
                    this.setState({ collapsed: true });
                }
            });
        }, 0);
    }

    addExpandedClass() {
        const toolboxRows = document.querySelectorAll('.blocklyTreeRow');
        [...toolboxRows].map(row => {
            row.classList.add("blocklyTreeRowExpanded");
        })
    }

    collapseToolbox = () => {
        this.setState({ collapsed: true });
        const toolboxDiv = document.querySelector('.blocklyToolboxDiv');
        const toolboxRows = document.querySelectorAll('.blocklyTreeRow');
        const selectedRows = document.querySelectorAll('.blocklyTreeSelected');
        const flyout = Blockly.mainWorkspace.getFlyout();
        toolboxDiv.style.width = "39px";
        toolboxDiv.style.height = "100%";
        flyout && flyout.hide();
        [...toolboxRows].map(row => {
            row.classList.remove("blocklyTreeRowExpanded");
        });
        [...selectedRows].map(row => {
            row.classList.remove("blocklyTreeSelected");
            row.style.backgroundColor = "";
        });

        // make toolbox scrollbars transparent
        const scrollbars = document.querySelectorAll('.scrollbar-thumb');
        [...scrollbars].map(scrollbar => {
            scrollbar.style.backgroundColor = 'transparent';
        });

        // collapse My Libraries category
        const toolbox = Blockly.mainWorkspace.getToolbox();
        toolbox && toolbox.tree_ && toolbox.tree_.collapseChildren();

        Blockly.mainWorkspace.recordDeleteAreas();

        let toolboxTrashcan = document.querySelector(".toolbox-trashcan");
        toolboxTrashcan.classList.remove("expanded");
    }

    expandToolbox = () => {
        this.setState({ collapsed: false });
        const toolboxDiv = document.querySelector('.blocklyToolboxDiv');
        const toolboxRows = document.querySelectorAll('.blocklyTreeRow');
        toolboxDiv.style.width = "199px";
        toolboxDiv.style.height = "calc(100% - 41px)";
        toolboxDiv.style.marginTop = "41px";
        [...toolboxRows].map(row => {
            row.classList.add("blocklyTreeRowExpanded");
        });

        // make toolbox scrollbars visible
        const scrollbars = document.querySelectorAll('.scrollbar-thumb');
        [...scrollbars].map(scrollbar => {
            scrollbar.style.backgroundColor = 'rgb(205, 206, 205)';
        });

        Blockly.mainWorkspace.recordDeleteAreas();

        let toolboxTrashcan = document.querySelector(".toolbox-trashcan");
        toolboxTrashcan.classList.add("expanded");
    }

    _initializeScroller () {
        const toolboxDiv = document.querySelector('.blocklyToolboxDiv');
        this.scroller = Scrollbar.init(toolboxDiv, {
            plugins: {
                disableScroll: {'direction': 'x'}
            }
        });
    }

    collapseButtonHandler = () => {
        this.collapseToolbox();
        Blockly.mainWorkspace.getToolbox().refreshSelection();
        Blockly.mainWorkspace.getToolbox().tree_.setSelectedItem(null);
        this.setState({ collapsed: true, pinned: false });
    }

    expandButtonHandler = () => {
        this.expandToolbox();
        this.setState({ collapsed: false, pinned: true });
    }

    onSearchFocus = () => {
        if (!this.state.pinned) {
            this.setState({ collapsed: false, pinned: true, isSearchExpanded: true });
        }
    }

    onSearchBlur = () => {
        if (this.state.isSearchExpanded) {
            this.setState({ pinned: false, isSearchExpanded: false });
        }
    }

    _updateScrollerGeometry () {
        this.scroller && this.scroller.update();
    }

    _attachHandlers () {
        // adjust scrollbar according to number of custom block libraries
        Blockly.mainWorkspace.toolbox_.HtmlDiv.addEventListener("pointerup", () => {
            setTimeout(() => {
                this.scroller && this.scroller.update();
            }, 0);
        });
        this._isHandlerAttached = true;
    }

    _initToolboxTooltips() {
        document.getElementById('workspaceMountDiv').querySelectorAll('.blocklyTreeRow').forEach(x => {
            let labelContainer = x.querySelector('.blocklyTreeLabel');
            if (!labelContainer || !labelContainer.innerText) return;
            let label = labelContainer.innerText;
            let workspaceDiv = document.getElementById('workspaceMountDiv');
            let tooltipText = Blockly.Tooltips.Toolbox[label];
            let didOpen = false;
            let timeout;
//            if (["Code Blocks", "My Libraries"].includes(label)) {
//                let beta = document.createElement("div");
//                beta.className = "beta";
//                labelContainer.appendChild(beta);
//            }
            let appendTooltip = () => {
                let tooltip = document.createElement('div');
                tooltip.innerText = tooltipText;
                tooltip.classList.add("toolboxTooltip");
                let pos = x.getBoundingClientRect();
                tooltip.style.left = (pos.right + 10) + 'px';
                tooltip.style.top = (pos.bottom - 38) + 'px';
                //MZ-TODO change color
                tooltip.style.backgroundColor = '#464646';
                // if (workspaceDiv.getBoundingClientRect().bottom - pos.bottom < 35) {
                //     tooltip.style.top = (pos.bottom - 70) + 'px';
                // } else {
                //     tooltip.style.top = pos.bottom + 'px';
                // }
                workspaceDiv.appendChild(tooltip);
                setTimeout(() => {
                    tooltip.classList.add("open")
                }, 1);
                didOpen = true
            };
            let removeTooltip = () => {
                clearTimeout(timeout);
                let tt = workspaceDiv.querySelector('.toolboxTooltip');
                if (tt) {
                    tt.parentNode.removeChild(tt)
                }
            };

            x.addEventListener('mousemove', () => {
                removeTooltip();
                if (!didOpen) {
                    timeout = setTimeout(appendTooltip, 500);
                }
            });
            x.addEventListener('mouseleave', () => {
                removeTooltip();
                didOpen = false;
            });
            x.addEventListener('click', () => {
                removeTooltip();
                didOpen = false;
            });
        });
    }

    _openSearch () {
        const mainWorkspace = Blockly.Workspace.getAll()[0];
        if (!mainWorkspace || !mainWorkspace.getToolbox()) { return; }
        const selectedToolboxCategory = mainWorkspace.getToolbox().tree_.children_.filter(child => child.element_.innerText === "Search")[0];

        if (selectedToolboxCategory) {
            Blockly.hideChaff(true);
            selectedToolboxCategory.element_.click();
        }
    }

    // hide some categories programmatically
    _updateHiddenCategories() {

        // this is an officially recommended way to hide category in old blockly,
        // however:
        // 1. this is not a good solution and more a "workaround"
        // 2. it executes with some delay, so you will see category flashing
        // 3. if you will use search, then the blocks from this category still come up, and the category becomes visible
        //
        // let the code be here until we have a better solution for hiding the category or until we update blockly

        /*
        const mainWorkspace = Blockly.Workspace.getAll()[0];
        if (!mainWorkspace || !mainWorkspace.getToolbox()) { return; }
        const repeaterCategory = mainWorkspace.getToolbox().tree_.children_[13];
        repeaterCategory.element_.classList.add("blocklyHidden");
        */
    }

    _updateToolbox (nextProps) {
        const { workspace, toolboxRef } = nextProps;
        this.setState({
            ...this.state,
            toolboxList: this._getToolboxList(nextProps.context, nextProps.libraries)
        });
        workspace && workspace.updateToolbox(toolboxRef.current);
        this.addExpandedClass();
        !this._isHandlerAttached && this._attachHandlers();
        this._initToolboxTooltips();
        this._updateHiddenCategories();
        this._openSearch(nextProps.searchQuery);
        this._initializeScroller();
        this._updateScrollerGeometry();
        this.state.collapsed && this.collapseToolbox();
    }

    render () {
        if(!this.props.context){
            return null
        }

        const { onSearch, searchQuery, setRestBlockError } = this.props;
        const { toolboxList } = this.state;

        return (
            <div>
                <SearchBar onSearch={onSearch} searchQuery={searchQuery} collapsed={this.state.collapsed}
                    collapseButtonHandler={this.collapseButtonHandler} expandButtonHandler={this.expandButtonHandler}
                    onSearchFocus={this.onSearchFocus} onSearchBlur={this.onSearchBlur} />
                <svg style={{ display: 'none' }}>
                    <xml id="toolbox" ref={this.props.toolboxRef}>
                        {
                            searchQuery
                                ? <Search searchQuery={searchQuery} context={this.props.context} blocks={toolboxList}/>
                                : null
                        }
                        <SnapBlocks context={this.props.context} toolboxList={toolboxList} setRestBlockError = {setRestBlockError} openJSEditor={this.props.openJSEditor}/>

                        <Logic context={this.props.context} blocks={toolboxList["Logic"]}/>
                        <Loops context={this.props.context} blocks={toolboxList["Loops"]}/>
                        <Math context={this.props.context} blocks={toolboxList["Math"]}/>
                        <Text context={this.props.context} blocks={toolboxList["Text"]}/>
                        <Lists context={this.props.context} blocks={toolboxList["Lists"]}/>
                        <Colour context={this.props.context} blocks={toolboxList["Colour"]}/>
                        <DatesCategory context={this.props.context} blocks={toolboxList["Dates"]}/>
                        { // show repeater blocks only when repeater is enabled or when there are any repeater blocks in workspace
                            this.props.context.enableRepeater || 
                            (!!this.props.context.code && this.props.context.code.indexOf('<block type="snap_repeater_') >= 0) ?
                                <Repeater context={this.props.context} blocks={toolboxList["Repeater"]}/>
                                : null
                        }
                        <TableView context={this.props.context} blocks={toolboxList["Table"]} setRestBlockError = {setRestBlockError}/>
                        <MobiControl context={this.props.context} blocks={toolboxList["MobiControl"]}/>
                        <Debug context={this.props.context} blocks={toolboxList["Debug Tools"]}/>

                        <sep id="separator_defaults"></sep>

                        <MyBlocks context={this.props.context} libraries={this.props.libraries}/>
                    </xml>
                </svg>
                <div className={"toolbox-trashcan"}><i></i>
                </div>
            </div>
        );
    }
}
